<template>
	<modal class="NoxModalWelcome" name="NoxModalWelcome" modal-width="600" :width="600" :height="'auto'" :adaptive="true" :scrollable="true" :clickToClose="false">
		<div class="nox_modal">
			<div class="nox_modal_head">
				<div class="nox_modal_title" v-html="$store.getters.getLanguageText('5.2', 0)"></div>
			</div>
			<div class="nox_modal_body">
				<form class="nox_modal_form">
					<div class="nox_modal_info">
						<p class="margin_bottom_20" v-html="$store.getters.getLanguageText('5.2', 1)"></p>
						<div class="nox_modal_info_row">
							<div class="nox_modal_info_row_label" v-html="$store.getters.getLanguageText('5.2', 2)"></div>
							<div class="nox_modal_info_row_value">
								<v-select class="nox_select style-chooser" v-model.trim="noxCountry" :placeholder="$store.getters.getLanguageText('5.2', 3)" :options="$parent.$parent.getSelectOptions(1)"></v-select>
								<div v-html="noxAlertCountry"></div>
							</div>
						</div>
						<div class="nox_modal_info_row">
							<div class="nox_modal_info_row_label" v-html="$store.getters.getLanguageText('5.2', 4)"></div>
							<div class="nox_modal_info_row_value">
								<input type="text" class="nox_input" v-model.trim="noxPhone" :placeholder="$store.getters.getLanguageText('5.2', 5)" autocomplete="off">
								<div v-html="noxAlertPhone"></div>
							</div>
						</div>
						<div class="nox_modal_info_row last">
							<div class="nox_modal_info_row_label" v-html="$store.getters.getLanguageText('5.2', 6)"></div>
							<div class="nox_modal_info_row_value">
								<input type="text" class="nox_input" v-model.trim="noxUsername" :placeholder="$store.getters.getLanguageText('5.2', 7)" autocomplete="off">
								<div v-html="noxAlertUsername"></div>
							</div>
						</div>
					</div>
					<div class="nox_modal_alert" v-html="noxAlert"></div>
					<div class="nox_modal_loading" :class="{ hide: !noxIsLoading }"><img src="@/assets/images/loading.gif"></div>
					<div class="nox_modal_buttons" :class="{ hide: noxIsLoading }">
						<button type="submit" class="nox_button common green" @click="save" v-html="$store.getters.getLanguageText('1.1', 7)"></button>
					</div>
				</form>
			</div>
		</div>
	</modal>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			noxTemp: '',
			noxAlert: '',
			noxAlertPhone: '',
			noxAlertCountry: '',
			noxAlertUsername: '',
			noxPhone: '',
			noxCountry: '',
			noxUsername: '',
			noxIsLoading: false,
			noxIsError: false
		}),
		methods: {
			resetAlerts: function() {

				this.noxAlert = '';
				this.noxAlertPhone = '';
				this.noxAlertCountry = '';
				this.noxAlertUsername = '';
			},
			getError: function(i) {

				this.noxIsError = true;

				if      (i == 1) { this.noxTemp = this.$store.getters.getLanguageText('1.3.1', 8); }
				else if (i == 2) { this.noxTemp = this.$store.getters.getLanguageText('1.3.1', 9); }
				else if (i == 3) { this.noxTemp = this.$store.getters.getLanguageText('1.3.1', 21); }
				else if (i == 4) { this.noxTemp = this.$store.getters.getLanguageText('1.3.1', 22); }
				else if (i == 5) { this.noxTemp = this.$store.getters.getLanguageText('1.3.1', 12); }
				else if (i == 6) { this.noxTemp = this.$store.getters.getLanguageText('1.3.1', 13); }
				else             { this.noxTemp = this.$store.getters.getLanguageText('1.3', 2); }

				return '<div class="alert"><b>' + this.$store.getters.getLanguageText('1.3', 0) + '</b> ' + this.noxTemp + '</div>';
			},
			save: function(event) {

				event.preventDefault();

				var _this = this;
				if (localStorage.getItem('noxToken')) {

					var config = {
						url: '/v2/account/users/welcome',
						data: { phone: _this.noxPhone, country: _this.noxCountry.code, username: _this.noxUsername },
						method: 'patch', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('noxToken') }
					};

					_this.resetAlerts();
					_this.noxIsError = false;

					if (!_this.noxCountry) {
						_this.noxAlertCountry = _this.getError(3);
					}
					if (!_this.noxPhone) {
						_this.noxAlertPhone = _this.getError(1);
					}
					else if (!_this.$store.state.noxRegexPhone.test(_this.noxPhone)) {
						_this.noxAlertPhone = _this.getError(2);
					}
					if (!_this.noxUsername) {
						_this.noxAlertUsername = _this.getError(5);
					}
					else if (!_this.$store.state.noxRegexUsername.test(_this.noxUsername)) {
						_this.noxAlertUsername = _this.getError(6);
					}

					if (!_this.noxIsError) {
						_this.noxIsLoading = true;
						axios(config).then(function(data) {
							if (data.status == 200) {
								_this.$parent.$parent.initAccountData(data.data);
								_this.$store.state.noxSystemSettings['vat_value'] = _this.$parent.$parent.getVatByCountry();
								_this.close();
								if (!_this.$store.state.noxAccountData.is_verification) {
									_this.$router.push({ path: '/account/verification' });
								}
							}
						}).catch(function(data) {
							if (data.response !== undefined) {
								if (data.response.status == 400) {
									if      (data.response.data.error == 'PHONE_IS_EMPTY') { _this.noxAlertPhone = _this.getError(1); }
									else if (data.response.data.error == 'PHONE_NOT_VALID') { _this.noxAlertPhone = _this.getError(2); }
									else if (data.response.data.error == 'COUNTRY_IS_EMPTY') { _this.noxAlertCountry = _this.getError(3); }
									else if (data.response.data.error == 'COUNTRY_NOT_VALID') { _this.noxAlertCountry = _this.getError(4); }
									else if (data.response.data.error == 'USERNAME_IS_EMPTY') { _this.noxAlertUsername = _this.getError(5); }
									else if (data.response.data.error == 'USERNAME_NOT_VALID') { _this.noxAlertUsername = _this.getError(6); }
								}
								else if (data.response.status == 401) { _this.$parent.$parent.exit(); }
							}
							if (!_this.noxIsError) {
								_this.noxAlert = _this.getError(0);
							}
						}).finally(function() {
							_this.noxIsLoading = false;
						});
					}
				}
				else {
					_this.$parent.$parent.exit();
				}
			},
			close: function() {
				this.$modal.hide('NoxModalWelcome');
			}
		}
	}
</script>
